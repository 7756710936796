import _ from 'lodash';
import { queryBusinessModels } from '@/api/model';
import AIFlowButton from '@/components/Button/Button.vue';

export default {
	name: 'Addbussiness',

	props: {
		idEdit: {
			type: Boolean,
			deleted: false
		}
	},

	data() {
		return {
			businessOptions: [
				{
					label: 'OpenAI',
					value: 0
				},
				{
					label: 'Anthropic Claude',
					value: 1
				},
				{
					label: 'Google Claude',
					value: 2
				},
				{
					label: 'Google Aistudio',
					value: 3
				}
			],

			modelOptions: [],

			formData: {
				business: 0,
				google_cloud_project_id: '',
				google_cloud_client_email: '',
				google_cloud_private_key: '',
				name: '',
				models: [],
				enterModelName: '',
				secret_key: '',
				org: '',
			}
		};
	},

	methods: {
		async updateModelOptions() {
			try {
				const modelsInfo = await queryBusinessModels({
					business: this.formData.business
				});

				this.modelOptions =  this.makeModelRenderOptions(modelsInfo.data.list);
			}
			catch(err) {
				this.$message({
          message: err.message ? err.message : 'Add fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
			}
		},

		makeModelRenderOptions(modelSrcList) {
			return modelSrcList.map((modelData) => {
				return {
					label: modelData.model_name,
					value: modelData.model_id
				};
			});
		},

		selectAllModel() {
			this.formData.models = this.getAllModelIds();
		},

		getFormValues() {
			return {
				...this.formData,
			};
		},

		setInitialData(channelData) {
			const {
				business,
				name,
				models,
				secret_key,
				org = '',
				total,
				google_cloud_project_id,
				google_cloud_client_email,
				google_cloud_private_key
			} = channelData;

			this.formData.business = business;
			this.formData.name = name;
			this.formData.models = _.cloneDeep(models);
			this.formData.secret_key = secret_key;
			this.formData.org = org;
			this.formData.google_cloud_project_id = google_cloud_project_id;
			this.formData.google_cloud_client_email = google_cloud_client_email;
			this.formData.google_cloud_private_key = google_cloud_private_key;
		},

		hasOption(value) {
			for (let i = 0; i < this.modelOptions.length; i++) {
				if (this.modelOptions[i].value === value) {
					return true;
				}
			}

			return false;
		},

		clearAllModle() {
			this.formData.models = [];
		},

		getAllModelIds() {
			return this.modelOptions.map((item) => {
				return item.value;
			});
		},

		changeProvider(v) {
			this.formData.secret_key = '';
			this.formData.org = '';
			this.formData.models = [];
			this.updateModelOptions();
		}
	},

	components: {
		AIFlowButton
	}
};