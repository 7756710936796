import { render, staticRenderFns } from "./Addbussiness.vue?vue&type=template&id=286d8c17&scoped=true"
import script from "./addbussiness.js?vue&type=script&lang=js&external"
export * from "./addbussiness.js?vue&type=script&lang=js&external"
import style0 from "./addbussiness.scss?vue&type=style&index=0&id=286d8c17&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286d8c17",
  null
  
)

export default component.exports