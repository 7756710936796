import AddbussinessForm from './Addbussiness/Addbussiness.vue';
import Loading from '@/components/Loading/Loading.vue';
import AIFlowButton from '@/components/Button/Button.vue';
import AIFlowEmpty from '@/components/Empty/Empty.vue';
import CopyRight from '@/components/CopyRight/CopyRight.vue';
import {
  addChannel,
  getChannel,
  updateChannel,
  delChannel,
  testChannel
} from '@/api/channel';

export default {
	name: 'Bussiness',

	data() {
		return {
      addbussinessDialogVisible: false,
      delChannelDialogVisible: false,
      editChannelDialogVisible: false,
      editChannelLoading: false,
      delLoading: false,
      pageLoading: true,
      pageSize: 100,
      total: 1,
      currentPage: 1,
			tableData: [],
      channelSrcDataList: {},
      createChannelLoading: false,
		};
	},

  methods: {
    init() {
      this.initFirstPage();
    },

    initFirstPage() {
      this.total = 1;
      this.loadPage(1, true);
    },

    pageChange() {
      this.loadPage(this.currentPage, true);
    },

    async loadPage(pageNumber, showLoading) {
      if (showLoading) {
        this.pageLoading = true;
      }

      try {
        const res = await getChannel({
          page_number: pageNumber,
          page_size: this.pageSize
        });
        
        if (res.code == 0) {
          this.channelSrcDataList = res.data.list;
          this.tableData = this.makeChannelList(this.channelSrcDataList);
          this.total = res.data.total_count;
        } else {
          this.$message({
            message: '查询失败',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
        this.$message({
          message: '查询失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.pageLoading = false;
    },

    findSrcDataById(id) {
      for (let i = 0; i < this.channelSrcDataList.length; i++) {
        if (this.channelSrcDataList[i].channel_id === id) {
          return this.channelSrcDataList[i];
        }
      }

      return null;
    },

    // 测试渠道
    async testChannel(channelData) {
      if (channelData.testChannelLoading) {
        return;
      }

      channelData.testChannelLoading = true;

      try {
        const testInfo = await testChannel({
          channel_id: channelData.id
        });

        this.$message({
          message: `Test success, Time consuming ${testInfo.data.min} milliseconds`,
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Test fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.loadPage(this.currentPage);
      channelData.testChannelLoading = false;
    },

    makeChannelList(srcList) {
      const result = [];

      srcList.forEach((channelData) => {
        const {
          channel_id,
          business,
          secret_key,
          channel_index,
          models,
          name,
          priority,
          org,
          status,
          time,
          used,
          weight,
          google_cloud_project_id,
          google_cloud_client_email,
          google_cloud_private_key
        } = channelData;
        
        result.push({
          id: channel_id,
          index: channel_index,
          name,
          business,
          status,
          time,
          used,
          priority,
          org,
          secret_key,
          weight,
          models,
          google_cloud_project_id,
          google_cloud_client_email,
          google_cloud_private_key,
          testChannelLoading: false,
          disableLoading: false
        });
      });

      return result;
    },

    statusFormat(status) {
      if (status === 0) {
        return 'Disabled';
      }

      if (status === 1) {
        return 'Active';
      }

      if (status === 2) {
        return 'Unknown';
      }

      return 'Unknown';
    },

    getBalance(total, used) {
      return (total - used).toFixed(2);
    },

    providerTrans(business) {
      if (business === 0) {
        return 'OpenAI';
      }

      if (business === 2) {
        return 'Google Claude';
      }

      if (business === 3) {
        return 'Google Aistudio';
      }

      return 'Anthropic Claude';
    },

    readyDelChannel(channel) {
      this.delChannelDialogVisible = true;
      this.delChannelData = channel;
    },

    async delChannelSessionOk() {
      if (this.delLoading) {
        return;
      }

      this.delLoading = true;

      try {
        await delChannel({
          channel_id: this.delChannelData.id
        });

        this.$message({
          message: 'Delete channel success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.delChannelDialogVisible = false;
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Delete channel fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.delLoading = false;
    },

    showAddDialog() {
      this.addbussinessDialogVisible = true;

      setTimeout(() => {
        this.$refs.addbussinessForm.updateModelOptions();
      }, 0);
    },

    // 新增渠道
    async createChannel() {
      if (this.createChannelLoading) {
        return;
      }

      this.createChannelLoading = true;

      const channelData = this.$refs.addbussinessForm.getFormValues();
      const requestData = this.makeRequestData(channelData);

      try {
        await addChannel(requestData);

        this.$message({
          message: 'Add channel success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.addbussinessDialogVisible = false;
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Add fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.createChannelLoading = false;
    },

    async editChannel() {
      if (this.editChannelLoading) {
        return;
      }

      this.editChannelLoading = true;

      const channelData = this.$refs.editChannelForm.getFormValues();
      const requestData = this.makeRequestData(channelData);

      try {
        await updateChannel({
          ...requestData,
          channel_id: this.readyEditChannelData.id
        });

        this.$message({
          message: 'Edit channel success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.editChannelDialogVisible = false;
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Add fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.editChannelLoading = false;
    },

    async updateIndex(opts) {
      const { id } = opts;
      const index = Number(opts.index);
      const oldData = this.findSrcDataById(id);

      if (oldData.channel_index === index) {
        return;
      }

      try {
        await updateChannel({
          channel_index: index,
          channel_id: id
        });

        this.$message({
          message: 'Update success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.loadPage(this.currentPage);
      }
      catch(err) {
        console.log(err);
        this.$message({
          message: err.message ? err.message : 'Update fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }
    },

    async updatePriority(opts) {
      const { id } = opts;
      const priority = Number(opts.priority);
      const oldData = this.findSrcDataById(id);

      if (oldData.priority === priority) {
        return;
      }

      try {
        await updateChannel({
          priority: priority,
          channel_id: id
        });

        this.$message({
          message: 'Update success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Update fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }
    },

    readyEditChannel(channelData) {
      this.editChannelDialogVisible = true;
      this.readyEditChannelData = channelData;
    
      setTimeout(() => {
        this.$refs.editChannelForm.setInitialData(channelData);
        this.$refs.editChannelForm.updateModelOptions();
      }, 0);
    },

    async changeChannelStatus(opts) {
      const { id, status } = opts;

      if (opts.disableLoading) {
        return;
      }

      opts.disableLoading = true;

      try {
        await updateChannel({
          status: status === 0 ? 1 : 0,
          channel_id: id
        });

        this.$message({
          message: 'Update success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Update fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      opts.disableLoading = false;
    },

    async updateWeight(opts) {
      const { id, weight } = opts;

      try {
        await updateChannel({
          weight: parseInt(weight, 10),
          channel_id: id
        });

        this.$message({
          message: 'Update success',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.loadPage(this.currentPage);
      }
      catch(err) {
        this.$message({
          message: err.message ? err.message : 'Update fail',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }
    },

    makeRequestData(data) {
      const {
        business,
        name,
        models,
        secret_key,
        org,
        google_cloud_project_id,
        google_cloud_client_email,
        google_cloud_private_key
      } = data;

      return {
        business,
        name,
        secret_key,
        org,
        models,
        google_cloud_project_id,
        google_cloud_client_email,
        google_cloud_private_key
      };
    }
  },

  mounted() {
    this.init();
  },

  components: {
    AddbussinessForm,
    Loading,
    AIFlowButton,
    AIFlowEmpty,
    CopyRight
  }
}